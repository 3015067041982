import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Row, Col } from 'react-bootstrap';
import { getGildformStatuses } from '../../utils/api';

const Details = ({ currentStatus }) => {
  const [gildformStatuses, setGildformStatuses] = useState([]);
  const getStatuses = async () => {
    const res = await getGildformStatuses(10);
    setGildformStatuses(res.settings);
  };
  useEffect(() => {
    getStatuses();
  }, []);

  const blurbs = useStaticQuery(graphql`
    {
      wordpressAcfProcessToolTip(wordpress_id: { eq: 225 }) {
        acf {
          process {
            heading
          }
          wordpress_3d_printing_group {
            heading
          }
          casting_group {
            heading
          }
          polishing_assembly_group {
            heading
          }
          shipping_group {
            heading
          }
          shipped_group {
            heading
          }
          returned_group {
            heading
          }
        }
      }
    }
  `);

  let msg = '';
  gildformStatuses.map((status) => {
    if (status.name === currentStatus.gildform_name) {
      msg = status.description;
    }
  });

  return (
    <Row style={{ paddingRight: '10px', paddingLeft: '10px' }}>
      <Col className='msg text-center'>
        <h3>{msg}</h3>
      </Col>
    </Row>
  );
};

export default Details;
