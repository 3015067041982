import React from 'react';
import {
  Document,
  View,
  Text,
  Font,
  StyleSheet,
  Page,
} from '@react-pdf/renderer';

import thinFont from './Brandon_thin.otf';
import lightFont from './Brandon_Text_Light.otf';
import regularFont from './Brandon_reg.otf';
import { getShippingPrice } from './OrderCard';

Font.register({
  family: 'Brandon Grotesque Thin',
  src: thinFont,
});

Font.register({
  family: 'Brandon Light, Brandon Grotesque Thin',
  fontDisplay: 'swap',
  src: lightFont,
});

Font.register({
  family: 'Brandon Regular',
  src: regularFont,
});

const style = StyleSheet.create({
  gildformText: {
    fontSize: 30,
    marginTop: 30,
    fontFamily: 'Brandon Grotesque Thin',
  },
  gildformSmall: {
    fontSize: 9,
    marginTop: 20,
    fontFamily: 'Brandon Grotesque Thin',
  },
  gildformSmall2: {
    fontSize: 9,
    marginTop: 5,
    fontFamily: 'Brandon Grotesque Thin',
  },
  wrapper: {
    marginLeft: 25,
  },
  invoiceText: {
    marginTop: 40,
    color: '#7c6371',
    fontFamily: 'Brandon Light, Brandon Grotesque Thin',
    fontDisplay: 'swap',
  },
  grayText: {
    fontSize: 10,
    color: '#757575',
    marginTop: 8,
    fontFamily: 'Brandon Light, Brandon Grotesque Thin',
    fontDisplay: 'swap',
  },
  grayText2: {
    fontSize: 10,
    color: '#757575',
    textAlign: 'right',
    marginTop: 8,
    position: 'absolute',
    right: '35%',
    fontFamily: 'Brandon Light, Brandon Grotesque Thin',
    fontDisplay: 'swap',
  },
  orderText: {
    textAlign: 'right',
    fontSize: 10,
    color: 'black',
    marginTop: 8,
    position: 'absolute',
    right: '22.5%',
    fontFamily: 'Brandon Light, Brandon Grotesque Thin',
    fontDisplay: 'swap',
  },
  personalData: {
    marginTop: 4,
    fontSize: 9,
    color: 'black',
    fontFamily: 'Brandon Regular',
  },
  row: {
    flexDirection: 'row',
    position: 'relative',
  },
  throw: {
    marginTop: 50,
    height: 17,
    width: '98%',
    flexDirection: 'row',
    position: 'relative',
    backgroundColor: '#e5e0e0',
  },
  productRow: {
    flexDirection: 'row',
    position: 'relative',
    marginTop: 30,
    marginBottom: 10,
  },
  description: {
    fontSize: 10,
    color: '#7c6371',
    position: 'absolute',
    left: 5,
    fontFamily: 'Brandon Light, Brandon Grotesque Thin',
    fontDisplay: 'swap',
  },
  productDescription: {
    position: 'absolute',
    fontFamily: 'Brandon Light, Brandon Grotesque Thin',
    fontDisplay: 'swap',
    left: 5,
    fontSize: 9,
  },
  quantity: {
    fontSize: 10,
    color: '#7c6371',
    position: 'absolute',
    right: '31%',
    fontFamily: 'Brandon Light, Brandon Grotesque Thin',
    fontDisplay: 'swap',
  },
  productQuantity: {
    position: 'absolute',
    right: '31%',
    fontSize: 9,
    fontFamily: 'Brandon Light, Brandon Grotesque Thin',
    fontDisplay: 'swap',
  },
  rate: {
    position: 'absolute',
    right: '22%',
    fontSize: 10,
    color: '#7c6371',
    fontFamily: 'Brandon Light, Brandon Grotesque Thin',
    fontDisplay: 'swap',
  },
  productRate: {
    position: 'absolute',
    right: '23.5%',
    fontSize: 9,
    fontFamily: 'Brandon Light, Brandon Grotesque Thin',
    fontDisplay: 'swap',
  },
  amount: {
    position: 'absolute',
    right: '9%',
    fontSize: 10,
    color: '#7c6371',
    fontFamily: 'Brandon Light, Brandon Grotesque Thin',
    fontDisplay: 'swap',
  },
  productAmount: {
    position: 'absolute',
    right: '9%',
    fontSize: 9,
    fontFamily: 'Brandon Light, Brandon Grotesque Thin',
    fontDisplay: 'swap',
  },
  hr: {
    height: 1,
    flexDirection: 'row',
    width: '97%',
    marginTop: 10,
    backgroundColor: '7c6371',
  },
  totalText: {
    color: '#a6a6a6',
    fontSize: 10,
    fontFamily: 'Brandon Light, Brandon Grotesque Thin',
    fontDisplay: 'swap',
  },
  totalPrice: {
    fontSize: 10,
    fontFamily: 'Brandon Light, Brandon Grotesque Thin',
    fontDisplay: 'swap',
  },
  tax: {
    fontSize: 9,
    marginLeft: 5,
    marginTop: 3,
    fontFamily: 'Brandon Light, Brandon Grotesque Thin',
    fontDisplay: 'swap',
  },
  shipping: {
    fontSize: 9,
    marginLeft: 5,
    marginTop: 3,
    fontFamily: 'Brandon Light, Brandon Grotesque Thin',
    fontDisplay: 'swap',
  },
  descriptionText: {
    marginTop: 10,
    marginLeft: 5,
    fontSize: 9,
    fontFamily: 'Brandon Light, Brandon Grotesque Thin',
    fontDisplay: 'swap',
  },
  inline: {
    height: 20,
    marginTop: 10,
    width: '97.5%',
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'row',
  },
  taxText: {
    position: 'absolute',
    right: '8%',
    fontSize: 9,
    fontFamily: 'Brandon Light, Brandon Grotesque Thin',
    fontDisplay: 'swap',
  },
});

const PDFDocument = ({ data: { order, user } }) => {
  const shippingPrice =
    getShippingPrice(order.shippingMethod) + (order.tax ? order.tax : 0);
  let total = order.orderItems.reduce(
    (acc, val) => acc + val.orderItemPrice.totalPrice,
    shippingPrice || 0
  );

  return (
    <Document>
      <Page>
        {order && (
          <View style={style.wrapper}>
            <Text style={style.gildformText}>GILDFORM</Text>
            <Text style={style.gildformSmall}>hello@gildform.com</Text>
            <Text style={style.gildformSmall2}>www.gildform.com</Text>
            <View>
              <Text style={style.invoiceText}>INVOICE</Text>
              <View style={style.row}>
                <Text style={style.grayText}>BILL TO</Text>
                <Text style={style.grayText2}>INVOICE</Text>
                <Text style={style.orderText}>#{order.id}</Text>
              </View>
              <View style={style.row}>
                <Text style={style.grayText2}>DATE</Text>
                <Text style={style.orderText}>
                  {order.createdAt.split('T')[0]}
                </Text>
              </View>
              <Text style={style.personalData}>{user.name}</Text>
              <Text style={style.personalData}>
                {`${user.address.city}, ${user.address.address1}, ${user.address.zipcode}`}
              </Text>
            </View>
            <View style={style.throw}>
              <Text style={style.description}>DESCRIPTION</Text>
              <Text style={style.rate}>RATE</Text>
              <Text style={style.amount}>AMOUNT</Text>
            </View>
            {order.orderItems.map((item) => (
              <View key={item.id} style={style.productRow}>
                <Text style={style.productDescription}>{`${
                  item.orderItemMaterial.name
                }, ${
                  item.orderItemPolish ? item.orderItemPolish.name : 'No Polish'
                } ${
                  item.orderItemPlating
                    ? item.orderItemPlating.name
                    : 'No plating'
                } ${
                  item.orderItemAssemblyOption
                    ? item.orderItemAssemblyOption.name
                    : 'No assembly'
                } `}</Text>
                <Text style={style.descriptionText}>{item.description}</Text>
                <Text style={style.productRate}>
                  {item.orderItemPrice.totalPrice.toFixed(2)}
                </Text>
                <Text style={style.productAmount}>
                  {item.orderItemPrice.totalPrice.toFixed(2)}
                </Text>
              </View>
            ))}
            <View style={style.productRow}>
              <Text style={style.shipping}>Shipping</Text>
              <Text style={style.taxText}>${shippingPrice}</Text>
            </View>
            <View style={style.productRow}>
              <Text style={style.tax}>Tax</Text>
              <Text style={style.taxText}>${order.tax || 0}</Text>
            </View>
            <View style={style.hr} />
            <View style={style.inline}>
              <Text />
              <Text style={style.totalText}>TOTAL</Text>
              <Text style={style.totalPrice}>${total.toFixed(2)}</Text>
            </View>
          </View>
        )}
      </Page>
    </Document>
  );
};

export default PDFDocument;
