import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import shopifyIcon from '../../images/shopify-icon.png';
import creditIcon from '../../images/creditkey-logo.png';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const getStatus = (status) => {
  let msg;
  switch (status) {
    case 'PRINTING':
      msg = `3D Printing`;
      break;
    case 'CASTING':
      msg = `Casting & Plating`;
      break;
    case 'POLISH':
      msg = `Polish & Assembly`;
      break;
    case 'DELIVERY':
      msg = `Shipped`;
      break;
    case 'RETURNED':
      msg = `Return Requested`;
      break;
    case 'SHIPPED':
      msg = `Delivered`;
      break;
  }
  return msg;
};

export const getShippingPrice = (code) => {
  switch (code) {
    case 'STANDARD':
      return 15;
    case 'INT-PRIO':
      return 100;
    case 'Pickup':
      return 0;
    case '1':
      return 15;
    case '2':
      return 100;
    case '3':
      return 0;
    default:
      return 0;
  }
};

const getOrderItemDescription = (orderItem) => {
  const itemType = orderItem.item.name;
  const material = orderItem.orderItemMaterial.name;
  const plating = orderItem.orderItemPlating
    ? orderItem.orderItemPlating.name
    : 'No plating';
  const polish = orderItem.orderItemPolish
    ? orderItem.orderItemPolish.name
    : 'No Polish';
  const assemblyOption = orderItem.orderItemAssemblyOption
    ? orderItem.orderItemAssemblyOption.name
    : 'No assembly option';

  return `${itemType}, ${material} , ${plating}, ${polish}, ${assemblyOption}`;
};

const ReadMore = ({ children }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const text = children;
  const result = isTruncated ? text.slice(0, 3) : text;
  const toggleIsTruncated = () => setIsTruncated(!isTruncated);

  return (
    <>
      {result}
      {isTruncated ? (
        <p
          style={{ color: '#7c6263', cursor: 'pointer' }}
          onClick={toggleIsTruncated}
        >
          See more...
        </p>
      ) : (
        <p
          style={{ color: '#7c6263', cursor: 'pointer' }}
          onClick={toggleIsTruncated}
        >
          See less...
        </p>
      )}
    </>
  );
};

const OrderCard = ({ order, isDelivered, onReturn }) => {
  const firstItem = order.orderItems[0];
  const shipping = getShippingPrice(order.shippingMethod) || 0;

  const tax = order.tax || 0;
  const total = order.orderItems.reduce(
    (acc, item) => acc + item.orderItemPrice.totalPrice,
    shipping + tax
  );

  return (
    <tr
      key={order.id}
      className='clickable'
      data-toggle='collapse'
      data-target={`#${order.id}`}
      aria-expanded='false'
      aria-controls={`#${order.id}`}
    >
      <td>#{order.id}</td>
      <td data-th='Image'>
        <img
          style={{ width: '100%' }}
          src={
            firstItem ? firstItem.image : 'This order item has no screenshot'
          }
          alt={
            firstItem && firstItem.image
              ? 'Screen shot of this jewelry piece'
              : ''
          }
        />
      </td>
      <td>
        <span className='items-number'>
          No# of items {order.orderItems.length}
        </span>
        <br />
        {order.orderItems.length > 1 ? (
          <ReadMore>
            {order.orderItems.map((orderItem) => (
              <div key={orderItem.id}>
                {getOrderItemDescription(orderItem)}
                <br />
              </div>
            ))}
          </ReadMore>
        ) : (
          order.orderItems.map((orderItem) =>
            getOrderItemDescription(orderItem)
          )
        )}
      </td>
      <td>
        <span className='price'> ${total.toFixed(2)}</span>
      </td>
      <td data-th='Status'>
        <span className='status'>{order.status ? order.status.gildform_name ? String(order.status.gildform_name).toUpperCase() : order.timelineStatus : 'Received'}</span>
      </td>
      <td>{order.createdAt.split('T')[0]}</td>
      <td>
        {order.notes && order.notes.toLowerCase().includes('shopify') && (
          <OverlayTrigger
            overlay={<Tooltip id='tooltip-bottom'>Shopify Order</Tooltip>}
          >
            <img
              style={{ width: '27px', height: '27px' }}
              src={shopifyIcon}
              alt='shopify-icon'
            />
          </OverlayTrigger>
        )}
        {order.notes && order.notes.includes('CK-') && (
          <OverlayTrigger
            overlay={<Tooltip id='tooltip-bottom'>Credit Key Order</Tooltip>}
          >
            <img
              style={{ width: '27px', height: '27px' }}
              src={creditIcon}
              alt='credit-key-icon'
            />
          </OverlayTrigger>
        )}
      </td>
      <td>
        {isDelivered && (
          <Button variant='link' onClick={() => onReturn()}>
            Return
          </Button>
        )}
      </td>
      <td>
        <span className='view-order'>
          <Link to={`/order/${order.id}`} state={order}>
            VIEW ORDER
          </Link>
        </span>
      </td>
    </tr>
  );
};

const mapStateToProps = (state) => ({
  login: state.login.auth && state.login.auth.profile,
  token: state.login.auth && state.login.auth.auth.token,
});

export default connect(mapStateToProps)(OrderCard);
