import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getGildformStatuses } from '../../utils/api';

const StepNumber = ({ isDone, current, index, title, message }) => {
  const barClass = isDone || current ? 'bar current' : 'bar';
  const bar = index === 0 ? null : <div className={barClass} />;

  if (isDone) {
    return (
      <OverlayTrigger
        placement='bottom'
        overlay={<Tooltip id='tooltip-bottom'>{message}</Tooltip>}
      >
        <Col className='status-step current '>
          <p>{title}</p>
          <div className='current done step-circle' data-tip={message}>
            <div className='check' />
          </div>
          {bar}
        </Col>
      </OverlayTrigger>
    );
  }

  if (current) {
    return (
      <OverlayTrigger
        placement='bottom'
        overlay={<Tooltip id='tooltip-bottom'>{message}</Tooltip>}
      >
        <Col className='status-step current'>
          <p>{title}</p>
          <div className='current step-circle' data-tip={message} />
          {bar}
        </Col>
      </OverlayTrigger>
    );
  }

  return (
    <OverlayTrigger placement='bottom' overlay={<Tooltip>{message}</Tooltip>}>
      <Col className='status-step'>
        <p>{title}</p>
        <div className='step-circle' data-tip={message} />
        {bar}
      </Col>
    </OverlayTrigger>
  );
};

const Timeline = ({ currentStatus }) => {
  const [gildformStatuses, setGildformStatuses] = useState([]);
  const getStatuses = async () => {
    const res = await getGildformStatuses(10);
    setGildformStatuses(res.settings);
  };

  useEffect(() => {
    getStatuses();
  }, []);

  const blurbs = useStaticQuery(graphql`
    {
      wordpressAcfProcessToolTip(wordpress_id: { eq: 225 }) {
        acf {
          process {
            tool_tip
          }
          wordpress_3d_printing_group {
            tool_tip
          }
          casting_group {
            tool_tip
          }
          polishing_assembly_group {
            tool_tip
          }
          shipping_group {
            tool_tip
          }
        }
      }
    }
  `);

  let statuses = gildformStatuses.map((status) => {
    return {
      index: Number(status.value),
      status: status.name,
      title: status.name,
      message: status.description,
      display: status.name === 'Returned' ? false : true,
    };
  });

  if (currentStatus === undefined) {
    return null;
  }
  const currentStep = statuses.findIndex(
    (status) =>
      status.status.toLowerCase().trim() === currentStatus.toLowerCase().trim()
  );

  return (
    <Row className='order-status'>
      {statuses
        .sort((a, b) => a.index - b.index)
        .map((status, index) => {
          if (status.display) {
            return (
              <OverlayTrigger key={index}>
                <StepNumber
                  key={index}
                  index={index}
                  title={status.title}
                  message={status.message}
                  isDone={
                    currentStatus === 'Shipping' ? true : index < currentStep
                  }
                  current={
                    currentStatus === 'Shipping' ? true : index === currentStep
                  }
                />
              </OverlayTrigger>
            );
          } else {
            return null;
          }
        })}
    </Row>
  );
};

export default Timeline;
