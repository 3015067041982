import React from 'react';
import { Row, Col, Table } from 'react-bootstrap';

const OrderItem = ({ item }) => (
  <tr>
    <td className='image-col'>
      <img
        style={{ width: '100%' }}
        src={item.image}
        alt='Screen shot of this jewelry piece'
      />
    </td>
    <td>
      <p>Item type: {item.item.name}</p>
      <p>
        Polish: {item.orderItemPolish ? item.orderItemPolish.name : 'No polish'}
      </p>
      <p>{item.orderItemPlating ? item.orderItemPlating.name : 'No plating'}</p>
      <p>
        {item.orderItemAssemblyOption
          ? item.orderItemAssemblyOption.name
          : 'No assembly'}
      </p>
    </td>
    <td>
      <p>{item.item.name}</p>
    </td>
    <td>
      <p>${item.orderItemPrice.totalPrice.toFixed(2)}</p>
    </td>
  </tr>
);

const OrderItems = ({ orderItems }) => (
  <Row className='details-item-holder mt-0'>
    <Col className='custom-col'>
      <Table>
        <thead>
          <tr>
            <th className='primary-text'>Order items</th>
            <th>Description</th>
            <th>Jewelry Type</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {orderItems.map((item) => (
            <OrderItem key={item.id} item={item} />
          ))}
        </tbody>
      </Table>
    </Col>
  </Row>
);

export default OrderItems;
